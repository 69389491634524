// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore, collection, doc, addDoc, setDoc, getDocs, deleteDoc, getDoc, query, where, limit, getCountFromServer, endBefore, limitToLast, or, and, orderBy, startAfter, Timestamp} from "firebase/firestore";
//import {getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged, updatePassword, setPersistence, browserLocalPersistence} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDGxEz-DCmEuhQpf22uHVPrGb7HpM7LjQk",
    authDomain: "momentproof-dev2.firebaseapp.com",
    projectId: "momentproof-dev2",
    storageBucket: "momentproof-dev2.appspot.com",
    messagingSenderId: "723503533922",
    appId: "1:723503533922:web:cf7dab426f5100b9c3263b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth();



export {firestore, collection, doc, addDoc, setDoc, getDocs, deleteDoc, getDoc, query, where, limit, getCountFromServer, endBefore, limitToLast, or, and, orderBy, startAfter, auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, deleteUser, onAuthStateChanged, Timestamp, updatePassword, setPersistence, browserLocalPersistence};
