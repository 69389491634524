import Swal from "sweetalert2";
import router from "./../../../router";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  firestore,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  getCountFromServer,
  or,
  and,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  orderBy,
  Timestamp,
  updatePassword,
  setPersistence,
  browserLocalPersistence
  //startAfter,
  //limit,
  //endBefore
} from "@/core/services/firebase";
import {limit} from "firebase/firestore";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";


export default {
  state: {
    companyFilter: null,
    recentLogs: null,
    companyAdd: null,
    userCount: null,
    momentCount: null,
    mediaCount: 0,
    mediaSize: 0
  },
  getters: {
    companyAdd(state) {
      return state.companyAdd;
    },
    companyFilter(state) {
      return state.companyFilter;
    },
    recentLogs(state) {
      return state.recentLogs;
    },
    userCount(state) {
      return state.userCount;
    },
    momentCount(state) {
      return state.momentCount;
    },
    mediaCount(state) {
      return state.mediaCount;
    },
    mediaSize(state) {
      return state.mediaSize;
    }
  },
  actions: {
    async checkActivation(context, payload) {
      try {
        const companyRef = collection(firestore, "companies");
        let queryConstraints = [];
        let q = null;
        queryConstraints.push(where("activationCode", "==", payload));
        q = query(companyRef, ...queryConstraints);

        const companyCount = (await getCountFromServer(q)).data().count;
        if (companyCount == 0) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        return false;
      }

    },
    async getDashboardInfo(context) {
      const logRef = collection(firestore, "logs");
      const userRef = collection(firestore, "user");
      const deliveryRef = collection(firestore, "delivery");
      let sumOfCount = 0;
      let sumOfSize = 0;

      let q = null;
      let queryConstraints = [];
      let queryConstraints2 = [];
      let filterByCompany = context.getters.companyFilter?.id || context.getters.mandatoryCompany;
      if (filterByCompany) {
        queryConstraints.push(where("company", "==", filterByCompany));
        queryConstraints2.push(and(where("company", "==", filterByCompany), or(where("action", "==", true), where("error", "==", true))));
      } else {
        queryConstraints2.push(or(where("action", "==", true), where("error", "==", true)));
      }

      const userCount = (await getCountFromServer(query(userRef, ...[...queryConstraints, where("role", "==", "MmFjtH2WGpbN2MDSmkcC")]))).data().count;
      const deliveryCount = (await getCountFromServer(query(deliveryRef, ...queryConstraints))).data().count;

      const deliverySnapshot = await getDocs(query(deliveryRef, ...queryConstraints));


      deliverySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.size) {
          sumOfSize = sumOfSize + data.size;
        }
        if (data.files) {
          sumOfCount = sumOfCount + data.files.length;
        }
      });
      queryConstraints2.push(orderBy("created_at", "desc"));
      queryConstraints2.push(limit(7));
      q = query(logRef, ...queryConstraints2);

      const docSnapshot = await getDocs(q);
      const list = docSnapshot.docs.map((v) => {
        return {
          ...v.data(),
        };
      });

      context.commit("setRecentLogs", list);
      context.commit("setUserCount", userCount);
      context.commit("setMomentCount", deliveryCount);
      context.commit("setMediaCount", sumOfCount);
      context.commit("setMediaSize", sumOfSize);
      setTimeout(() => {
        context.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      },700);
    },
    async authListener(context) {
      await setPersistence(auth, browserLocalPersistence);

      onAuthStateChanged(auth, async (user) => {
        if (user == null && context.getters.currentUser != null) {
          context.commit("logOut");

          router.push({ name: "login" });
          return;
        }

        if (user != null) {
          const documentRef = doc(firestore, "admins/" + user.uid);
          const documentSnapshot = await getDoc(documentRef);
          if (documentSnapshot.exists()) {
            localStorage.setItem('isAuthSet', "true");
            const userRef = doc(firestore, "user/" + user.uid);
            const userSnapshot = await getDoc(userRef);
            const data = await userSnapshot.data();
            context.commit("setUser", {...data, id: user.uid});
            if (!router.currentRoute.matched[0].meta.requiresAuth) {
              router.push({ name: "dashboard" });
            } else if (router.currentRoute.name != "dashboard") {
              router.push({ name: "dashboard" });
            }

            setTimeout(() => {
              context.dispatch("getDashboardInfo");

            },1);
          } else {
            context.dispatch("signOutWithFirebase");
            if (router.currentRoute.name != "login") {
              router.push({ name: "login" });
            }
          }
        }
      });
    },
    async firebaseVerifyAuth(context, payload) {
      try {
        const isAuthSet = localStorage.getItem('isAuthSet');
        if (isAuthSet == "true" && !context.getters.currentUser) {
          context.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          return {r: true};
        }

        if (payload.to.matched[0].meta.requiresAuth && context.getters.currentUser) {
          return {r: true};
        } else if (!payload.to.matched[0].meta.requiresAuth) {
          return {r: true};
        } else if (payload.to.matched[0].meta.requiresAuth && !context.getters.currentUser) {
          return {r: false, target: "/login"};
        } else if (!payload.to.matched[0].meta.requiresAuth && context.getters.currentUser) {
          return {r: false, target: "/dashboard"};
        }
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async createUserWithoutAuthState(context, payload) {
      Swal.showLoading();
      try {
        const response = await fetch('https://us-central1-momentproof-dev2.cloudfunctions.net/createUser',
            {
              method: 'POST',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: payload.email,
                password: payload.password
              }),
            });

        if (!response.ok) {
          throw new Error('Failed to create user');
        }

        const data = await response.json();
        return data.uid;
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async createUser(context, payload) {
      Swal.showLoading();
      try {
        const uc = await createUserWithEmailAndPassword(auth, payload.email, payload.password);
        await setDoc(doc(firestore, "admins", uc.user.uid), {});
        return uc.user.uid;
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async signOutWithFirebase(context) {
      Swal.showLoading();
      try {
        await signOut(auth);
        localStorage.setItem('isAuthSet', "false");
        context.commit("setCompanyFilter", null);
        Swal.close();
        setTimeout(() => {
          context.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },700);
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async signInWithFirebase(context, payload) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password);
        const documentRef = doc(firestore, "admins/" + userCredential.user.uid);
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
          localStorage.setItem('isAuthSet', "true");
          const userRef = doc(firestore, "user/" + userCredential.user.uid);
          const userSnapshot = await getDoc(userRef);
          const data = await userSnapshot.data();
          context.commit("setUser", {...data, id: userCredential.user.uid});
          context.dispatch("getDashboardInfo");
          return false;
        } else {
          return "Invalid email or password";
        }
      } catch (e) {
        return e.message;
      }
    },
    async deleteFirebaseUser(context, payload) {
      try {
        const documentRef = doc(firestore, "admins", payload.id);
        await deleteDoc(documentRef);
        //await auth.deleteUser(payload.id);
        return true;
      } catch (e) {
        context.commit("errorMessage", e.message);
        return false;
      }
    },
    async getList(context, payload) {
      const companyRef = collection(firestore, payload.target);

      try {
        let q = null;
        let queryConstraints = [];
        let orArray = [];
        if (payload.query && payload.query.value !== "") {
          payload.query.targets.forEach(v => {
            const andArray = [];
            andArray.push(where(v, ">=", payload.query.value));
            andArray.push(where(v, "<=", payload.query.value + "\uf8ff"));
            orArray.push(and(...andArray));
          });
        }

        const filterArray = [];
        payload.filters.forEach(v => {
          if (v.value != "") {
            filterArray.push(where(v.field, v.filter, v.value));
          }
          //queryConstraints.push(where(v.field, v.filter, v.value));
        });
        filterArray.push(or(...orArray));
        let filterByCompany = context.getters.companyFilter?.id || context.getters.mandatoryCompany;

        if (filterByCompany) {
          filterArray.push(where("company", "==", filterByCompany));
        }
        queryConstraints.push(and(...filterArray));

        const c = (await getCountFromServer(query(companyRef, ...queryConstraints))).data().count;

        //queryConstraints.push(limit(payload.pageLimit));
        if (payload.sortBy) {

          queryConstraints.push(orderBy(payload.sortBy, payload.sortOrder));
        }

        if (payload.last && payload.last != 0) {
          //const endBeforeDoc = await doc(companyRef, payload.last);
          //console.log('---------next page----------------------', endBeforeDoc);
          //queryConstraints.push(startAfter(endBeforeDoc));
        }
        if (payload.prev && payload.prev != 0) {
          //const endBeforeDoc = await doc(companyRef, payload.prev);
          //console.log('------------prev page-------------------', endBeforeDoc);
          //queryConstraints.push(endBefore(endBeforeDoc));
        }

        q = query(companyRef, ...queryConstraints);

        const docSnapshot = await getDocs(q);
        const list = docSnapshot.docs.map((v) => {
          return {
            id: v.id,
            ...v.data(),
          };
        });

        return {list: list, totalItems: c};
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async getListWithoutFilter(context, payload) {
      const companyRef = collection(firestore, payload.target);

      try {
        let q = null;
        let queryConstraints = [];
        let orArray = [];
        if (payload.query && payload.query.value !== "") {
          payload.query.targets.forEach(v => {
            const andArray = [];
            andArray.push(where(v, ">=", payload.query.value));
            andArray.push(where(v, "<=", payload.query.value + "\uf8ff"));
            orArray.push(and(...andArray));
          });
        }

        const filterArray = [];
        payload.filters.forEach(v => {
          if (v.value != "") {
            filterArray.push(where(v.field, v.filter, v.value));
          }
          //queryConstraints.push(where(v.field, v.filter, v.value));
        });
        filterArray.push(or(...orArray));

        queryConstraints.push(and(...filterArray));

        const c = (await getCountFromServer(query(companyRef, ...queryConstraints))).data().count;

        //queryConstraints.push(limit(payload.pageLimit));
        if (payload.sortBy) {

          queryConstraints.push(orderBy(payload.sortBy, payload.sortOrder));
        }

        if (payload.last && payload.last != 0) {
          //const endBeforeDoc = await doc(companyRef, payload.last);
          //console.log('---------next page----------------------', endBeforeDoc);
          //queryConstraints.push(startAfter(endBeforeDoc));
        }
        if (payload.prev && payload.prev != 0) {
          //const endBeforeDoc = await doc(companyRef, payload.prev);
          //console.log('------------prev page-------------------', endBeforeDoc);
          //queryConstraints.push(endBefore(endBeforeDoc));
        }

        q = query(companyRef, ...queryConstraints);

        const docSnapshot = await getDocs(q);
        const list = docSnapshot.docs.map((v) => {
          return {
            id: v.id,
            ...v.data(),
          };
        });

        return {list: list, totalItems: c};
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async getData(context, payload) {
      try {
        const documentRef = doc(firestore, payload);
        const documentSnapshot = await getDoc(documentRef);
        if (documentSnapshot.exists()) {
          return {id: documentSnapshot.id, ...documentSnapshot.data()};
        } else {
          context.commit("errorMessage", "No Such a File");
        }
      } catch (error) {
        context.commit("errorMessage", error.message);
      }
    },
    async deleteData(context, payload) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed',
        });
        if (result && result.isConfirmed) {
          Swal.showLoading();
          const documentRef = doc(firestore, payload.target, payload.id);
          await deleteDoc(documentRef);
          Swal.close();
          return true;
        } else {
          return false;
        }
      } catch (e) {
        context.commit("errorMessage", e.message);
      }
    },
    async saveData(context, payload) {
      Swal.showLoading();

      const formData = payload.form;
      formData.created_at = Timestamp.now();
      if (payload.id == 0) {
        try {
          const docRef = await addDoc(
            collection(firestore, payload.target),
            formData
          );
          if (docRef.id) {
            context.commit(
              "successMessage",
              "The application has been successfully submitted!"
            );
          }
          return docRef.id;
        } catch (e) {
          context.commit("errorMessage", e.message);
        }
      } else {
        try {
          await setDoc(doc(firestore, payload.target, payload.id), payload.form);
          context.commit(
              "successMessage",
              "The application has been successfully submitted!"
          );

          return true;
        } catch (e) {
          context.commit("errorMessage", e.message);
        }
      }
    },
    async updatePassword(context, payload) {
      Swal.showLoading();
      try {
        const user = auth.currentUser;
        await updatePassword(user, payload)
        context.commit(
            "successMessage",
            "Your Password successfully changed"
        );
        return true;
      } catch (e) {
        context.commit("errorMessage", e.message);
        return false;
      }
    }
  },
  mutations: {
    setCompanyAdd(state, payload) {
      state.companyAdd = payload;
    },
    setMediaSize(state, payload) {
      state.mediaSize = payload;
    },
    setMediaCount(state, payload) {
      state.mediaCount = payload;
    },
    setRecentLogs(state, payload) {
      state.recentLogs = payload;
    },
    setMomentCount(state, payload) {
      state.momentCount = payload;
    },
    setUserCount(state, payload) {
      state.userCount = payload;
    },
    setCompanyFilter(state, payload) {
      state.companyFilter = payload;
    },
    errorMessage(state, payload) {
      Swal.fire({
        title: "Error",
        text: payload,
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    successMessage(state, payload) {
      Swal.fire({
        title: "",
        text: payload,
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
};
